import React, {useEffect, useRef, useState} from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HC_more from 'highcharts/highcharts-more'
import {useResizeDetector} from 'react-resize-detector';
import Box from '@mui/material/Box';
import alpha from 'color-alpha'

import useDebounce from 'hooks/useDebounce';

HC_more(Highcharts)

const OpportunitiesChart = ({
                                yLabel, xLabel, redraw = '', height = 0, data = [], onClick = () => {
    }
                            }) => {
    const charOptions = () => {
        return {
            chart: {
                type: 'bubble',
                spacingTop: 20,
                spacingLeft: -4,
                ...(height > 0 && {height}),
                createDataCoppy: true,
                animation: false,
            },
            credits: {
                enabled: false
            },
            title: {
                text: null,
            },
            xAxis: {
                title: {
                    text: xLabel,
                    style: {fontSize: '0.875rem', fontFamily: 'inherit'},
                    margin: 15,
                },
                labels: {
                    style: {fontSize: '0.875rem', fontFamily: 'inherit'},
                    format: '{value:.2f}'

                }
            },
            yAxis: {
                title: {
                    text: yLabel,
                    style: {fontSize: '0.875rem', fontFamily: 'inherit'},
                    margin: 15,
                },
                labels: {
                    enabled: true,
                    style: {fontSize: '0.875rem', fontFamily: 'inherit'},
                    format: '{value:.2f}'
                },
                gridLineWidth: 0,
            },
            legend: {
                enabled: false,
                align: 'left',
                verticalAlign: 'middle',
                x: 0,
                y: 0,
                layout: 'vertical',
                itemMarginTop: 10,
                itemMarginBottom: 10,
                width: '20%',
                bubbleLegend: {
                    enabled: true,
                    borderWidth: 1,
                    connectorDistance: 40,
                    maxSize: 60,
                    minSize: 30,
                    borderColor: '#000000',
                    color: '#ffffff',
                },
                title: {
                    text: 'Impact Levers',
                    style: {
                        fontStyle: 'bold'
                    }
                },
            },
            plotOptions: {
                series: {
                    allowPointSelect: true,
                    dataLabels: {
                        enabled: false,
                        format: '{point.name}'
                    },
                    sizeBy: 'width',
                    states: {
                        inactive: {
                            opacity: 1
                        },
                        hover: {
                            halo: null
                        }
                    },
                },
                bubble: {
                    minSize: 30,
                    maxSize: 60
                }
            },
            tooltip: {
                headerFormat: "",
                useHTML: true,
                backgroundColor: '#ffffff',
                borderColor: '#e5e5e5',
                formatter: function () {
                    return `<span style="font-size: 0.875rem; width:100%; line-height: 16px; font-family: Helvetica,Arial,serif;">
                            <table>
                            <tbody>
                            <tr>
                                <td style="font-weight: 600">Segment</td>
                                <td>${this.point.segment_display_name}
                                ${(!isNaN(this.point.segment_lb) && !isNaN(this.point.segment_ub) && (this.point.segment_lb > 0 || isNaN(this.point.segment_ub) > 0)) ? `[ 
                                ${isNaN(this.point.segment_lb) ? this.point.segment_lb : (this.point.segment_lb).toFixed(3)}, 
                                ${isNaN(this.point.segment_ub) ? this.point.segment_ub : (this.point.segment_ub).toFixed(3)}]` : ''}
                                ${isNaN(this.point.segment_ub) && isNaN(this.point.segment_lb) ? `[${this.point.segment_lb},${this.point.segment_ub}]` : ''}
                                </td>
                            </tr>
                            <tr>
                                <td style="font-weight: 600">Elasticity</td>
                                <td>${(this.point.y).toFixed(3)}</td>
                            </tr>
                            <tr>
                                <td style="font-weight: 600">Impact Lever</td>
                                <td>${this.point.impact_lever ? this.point.impact_lever.name : ''}</td>
                            </tr>
                            <tr>
                                <td style="font-weight: 600">Population</td>
                                <td>${this.point.population}</td>
                            </tr>
                            </tbody>
                            </table>
                            </span>`;
                },
            },
        };
    };

    // const colors = ["#0794d3", "#c50082", "#49ac43", "#df812e", "#3401af", "#cdae00", "#ff0000"];
    const baseColors = ['#0794d3', '#c50082', '#49ac43', '#df812e', '#3401af', '#cdae00', '#ff0000'];

    function generateAlternatingVariants(baseColors, numVariants) {
        const variants = [];
        const steps = Math.ceil(numVariants / baseColors.length); // Variants per base color

        for (let i = 0; i < numVariants; i++) {
            const colorIndex = i % baseColors.length; // Cycle through base colors
            const factor = 1 - ((i / baseColors.length) % steps) * 0.2; // Adjust lightness factor
            const colorVariant = shadeColor(baseColors[colorIndex], factor);
            variants.push(colorVariant);
        }

        return variants;
    }

// Helper function to adjust lightness of HEX color
    function shadeColor(color, factor) {
        const rgb = hexToRgb(color);
        const shaded = {
            r: Math.min(255, Math.max(0, Math.round(rgb.r * factor))),
            g: Math.min(255, Math.max(0, Math.round(rgb.g * factor))),
            b: Math.min(255, Math.max(0, Math.round(rgb.b * factor))),
        };
        return rgbToHex(shaded.r, shaded.g, shaded.b);
    }

    function hexToRgb(hex) {
        const bigint = parseInt(hex.slice(1), 16);
        return {
            r: (bigint >> 16) & 255,
            g: (bigint >> 8) & 255,
            b: bigint & 255,
        };
    }

    function rgbToHex(r, g, b) {
        return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
    }

    const colors = generateAlternatingVariants(baseColors, 100);

    const [options, setOptions] = useState(charOptions());
    const chartRef = useRef(null);
    const {width,height:cHeight, ref} = useResizeDetector();
    const chartWidth = useDebounce(width, 200);
    const chartHeight = useDebounce(cHeight, 200);

    const handleClick = (data) => {
        onClick(data);
    }


    useEffect(() => {
        const series = [];

        data.forEach(function (group, index) {
            series.push({
                data: group.data.sort(function (a, b) {
                    return b.population - a.population;
                }),
                name: group.name,
                visible: group.visible,
                showInLegend: true,
                shadow:false,
                point: {events: {click: handleClick}},
                cursor:'pointer',
                marker: {
                    fillColor: alpha(colors[index], .3),
                    lineWidth: 1,
                    fillOpacity: 1,
                    lineColor: alpha(colors[index], .3),
                    states: {
                        hover: {
                            fillColor: alpha(colors[index], .6),
                            lineColor: colors[index],
                            fillOpacity: 1,
                            lineWidth: 1,
                            lineWidthPlus:0,
                        },
                        select: {
                            fillColor: alpha(colors[index], 0.6),
                            lineColor: colors[index],
                            fillOpacity: 1,
                            lineWidth: 3,
                        }
                    },
                }

            })
        })

        setOptions({
            ...charOptions(),
            series: series
        });
    }, [data]);

    useEffect(() => {
        if (chartRef) {
            chartRef.current.chart.reflow();
        }
    }, [redraw, chartWidth,chartHeight])

    return (<Box ref={ref} sx={{height: "100%"}}>
            <HighchartsReact containerProps={{style: {height: "100%"}}} ref={chartRef} highcharts={Highcharts}
                             allowChartUpdate={true}
                             options={options}/>
        </Box>
    );
};

export default OpportunitiesChart;
