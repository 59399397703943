import React, { useEffect, useRef, useState } from 'react';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useResizeDetector } from 'react-resize-detector';
import alpha from 'color-alpha';

import useOpportunities from 'hooks/opportunity/use-opportunity';
import LoaderSpinner from 'components/elements/LoaderSpinner';
import AlertCard from 'components/elements/AlertCard';
import CircleSize from 'components/images/circle-size.png';

import OpportunitiesChart from './opportunities-chart';
import OpportunitiesSummary from './opportunities-summary';

const OpportunitiesLegend = ({ data, handleClick, activeLegend }) => {
  // const colors = ['#0794d3', '#c50082', '#49ac43', '#df812e', '#3401af', '#cdae00', '#ff0000']*50;

  const baseColors = ['#0794d3', '#c50082', '#49ac43', '#df812e', '#3401af', '#cdae00', '#ff0000'];

  function generateAlternatingVariants(baseColors, numVariants) {
    const variants = [];
    const steps = Math.ceil(numVariants / baseColors.length); // Variants per base color

    for (let i = 0; i < numVariants; i++) {
      const colorIndex = i % baseColors.length; // Cycle through base colors
      const factor = 1 - ((i / baseColors.length) % steps) * 0.2; // Adjust lightness factor
      const colorVariant = shadeColor(baseColors[colorIndex], factor);
      variants.push(colorVariant);
    }

    return variants;
  }

// Helper function to adjust lightness of HEX color
  function shadeColor(color, factor) {
    const rgb = hexToRgb(color);
    const shaded = {
      r: Math.min(255, Math.max(0, Math.round(rgb.r * factor))),
      g: Math.min(255, Math.max(0, Math.round(rgb.g * factor))),
      b: Math.min(255, Math.max(0, Math.round(rgb.b * factor))),
    };
    return rgbToHex(shaded.r, shaded.g, shaded.b);
  }

  function hexToRgb(hex) {
    const bigint = parseInt(hex.slice(1), 16);
    return {
      r: (bigint >> 16) & 255,
      g: (bigint >> 8) & 255,
      b: bigint & 255,
    };
  }

  function rgbToHex(r, g, b) {
    return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
  }

  const colors = generateAlternatingVariants(baseColors, 100);


  return <Grid item xs={12} container spacing={2} sx={{ height: 'fit-content' }}>
    <Grid item xs={12}>
      <Typography sx={{ fontSize: '0.875rem', fontWeight: 700 }}>Circle size</Typography>
    </Grid>
    <Grid item xs={12} container flexWrap='nowrap' spacing={2} alignItems={'center'}>
      <Grid item><img width={'72px'} height={'72px'} src={CircleSize} alt={''} /></Grid>
      <Grid item>
        <Typography sx={{ fontSize: '0.875rem', fontWeight: 700 }}>Size</Typography>
        <Typography sx={{ fontSize: '0.875rem' }}>Large</Typography>
        <Typography sx={{ fontSize: '0.875rem' }}>Medium</Typography>
        <Typography sx={{ fontSize: '0.875rem' }}>Small</Typography>
      </Grid>
      <Grid item>
        <Typography sx={{ fontSize: '0.875rem', fontWeight: 700 }}>Count</Typography>
        <Typography sx={{ fontSize: '0.875rem' }}>> 1000</Typography>
        <Typography sx={{ fontSize: '0.875rem' }}>100-1000</Typography>
        <Typography sx={{ fontSize: '0.875rem' }}>1-100</Typography>
      </Grid>
    </Grid>
    <Grid item xs={12}>
      <Typography sx={{ fontSize: '0.875rem', fontWeight: 700 }}>Impact levers</Typography>
    </Grid>
    <Grid container item xs={12} spacing={1}>
      {data.map((group, index) => (
        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{
            backgroundColor: alpha(colors[index], 0.3),
            width: '0.875em',
            height: '0.875em',
            borderRadius: '100%',
            cursor: 'pointer',
          }} onClick={() => handleClick(group['id'])}></Box>
          <Typography sx={{
            paddingLeft: '0.5em',
            cursor: 'pointer',
            fontSize: '0.875rem', ...(activeLegend === group['id'] && { fontWeight: 700 }),
          }}
                      onClick={() => handleClick(group['id'])}>{group['name']}</Typography>
        </Grid>))
      }
    </Grid>
  </Grid>;
};

const OpportunitiesView = ({
                             height,
                             filters,
                             openDrilldown,
                             redraw,
                             xLabel,
                             setLastPipeline,
                             setLastSuccessPipeline,
                             setStatus,
                             setRecalculate,
                             handleChangeImpactLeaver,
                             outcomes = [],
                             impactLevers = [],
                           }) => {
  const { t } = useTranslation();
  const datasetId = useSelector((state) => state.app.dataset);
  const initData = useSelector((state) => state.app.initData);
  const [page, setPage] = useState(1);
  const [segments, setSegments] = useState([]);
  const [redrawChart, setRedrawChart] = useState(redraw);
  const [bestOpportunities, setBestOpportunities] = useState([]);
  const [selectedOpportunities, setSelectedOpportunities] = useState(null);
  const [activeLegend, setActiveLegend] = useState('');

  const [summaryHeight, setSummaryHeight] = useState(0);
  const ref = useRef(null);
  const { width, height: refHeight } = useResizeDetector({
    targetRef: ref,
  });

  const isEnabled = () => {
    if (initData && initData.opportunities.outcomes) {
      return initData.opportunities.outcomes.map((outcome) => {
          return outcome.id;
        }).includes(filters.outcome)
        && initData.opportunities.selected_outcome.id === filters.outcome;
    } else {
      return false;
    }
  };

  const { data, status } = useOpportunities({
    outcome: filters.outcome,
    lever: filters.impactLever === 'all' ? '' : filters.impactLever,
    size: filters.show,
    page,
    meanFeature: filters.meanFeature,
    datasetId: datasetId,
    enabled: isEnabled(),
  });

  useEffect(() => {
    if (ref && ref.current) {
      setSummaryHeight(ref.current.clientHeight);
    }
  }, [width, refHeight, data]);

  useEffect(() => {
    if (data && data.meta) {
      setLastPipeline(data.meta.last_pipeline);
      setLastSuccessPipeline(data.meta.last_success_pipeline);
      setRecalculate(data.meta.recalculate);
    }
  }, [data]);

  useEffect(() => {
    if (data && data.meta) {
      setLastPipeline(data.meta.last_pipeline);
      setLastSuccessPipeline(data.meta.last_success_pipeline);
    }
  }, [initData]);

  useEffect(() => {
    setStatus(status);
  }, [status]);

  useEffect(() => {
    setPage(1);
  }, [filters]);

  useEffect(() => {
    if (data && data.data) {
      setBestOpportunities(data.data.slice(0, 5));
    }
  }, [data]);

  const bubbleSize = (size) => {
    switch (size) {
      case 'SMALL':
        return 30;
      case 'MEDIUM':
        return 45;
      case 'LARGE':
        return 60;
      default:
        return 10;
    }
  };

  useEffect(() => {
    if (data && data.data) {
      const flattenedSegments = data.data.reduce((group, item) => {
        const impact_lever = item.impact_lever.id;
        const index = group.findIndex(s => s.id === impact_lever);
        if (index >= 0) {
          group[index].data = [...group[index].data, {
            x: item.mean_value_for_x,
            y: item.elasticity,
            z: bubbleSize(item.bubble_size),
            impact_size: item.impact_size,
            segment: item.segment_1.segment,
            segment_feature: item.segment_1.segment_feature,
            segment_display_name: item.segment_1.segment_display_name,
            population: item.population,
            segment_ub: item.segment_1.segment_ub,
            segment_lb: item.segment_1.segment_lb,
            impact_lever: item.impact_lever,
          }];
        } else {
          group.push({
            data: [{
              x: item.mean_value_for_x,
              y: item.elasticity,
              z: bubbleSize(item.bubble_size),
              impact_size: item.impact_size,
              segment: item.segment_1.segment,
              segment_feature: item.segment_1.segment_feature,
              segment_display_name: item.segment_1.segment_display_name,
              population: item.population,
              segment_ub: item.segment_1.segment_ub,
              segment_lb: item.segment_1.segment_lb,
              impact_lever: item.impact_lever,
            }],
            name: item.impact_lever.name,
            id: item.impact_lever.id,
            visible: true,
          });
        }
        return group;
      }, []);
      setSegments(flattenedSegments);
    }
  }, [data]);

  const handleCharDataUpdate = (value) => {
    const flattened = [];
    for (const group of segments) {
      const list = [];
      for (const segment of group.data) {
        list.push({
          ...segment,
          ...(!!value ? {
            selected: segment.segment === value.segment
              && segment.impact_lever.id === value.impact_lever && segment.segment_feature === value.segment_feature,
          } : { selected: false }),
        });
      }
      flattened.push({
        ...group,
        data: list,
      });
    }
    setSegments(flattened);
  };

  useEffect(() => {
    if (redraw === '') {
      handleCharDataUpdate(null);
    }
  }, [redraw]);

  const handleClick = (value) => {
    handleCharDataUpdate({
      segment: value.point.segment,
      segment_feature: value.point.segment_feature,
      impact_lever: value.point.impact_lever.id,
    });
    openDrilldown(value);
    setSelectedOpportunities(null);
  };

  const handleClickBestOpportunity = (value) => {
    handleCharDataUpdate({
      segment: value.segment_1.segment,
      segment_feature: value.segment_1.segment_feature,
      impact_lever: value.impact_lever.id,
    });
    setRedrawChart(value.segment_1.segment + value.segment_1.segment_feature + value.impact_lever.id);
    setSelectedOpportunities(value);
  };

  const handleLegendClick = (lever) => {
    let flattened = [];
    if (activeLegend === lever) {
      setActiveLegend('');
      flattened = segments.map(s => {
        return {
          ...s,
          visible: true,
        };
      });
    } else {
      setActiveLegend(lever);
      flattened = segments.map(s => {
        if (lever === s.id) {
          return {
            ...s,
            visible: true,
          };
        } else {
          return {
            ...s,
            visible: false,
          };
        }
      });
    }

    setSegments(flattened);
  };

  return (
    <Grid container spacing={2} id={'opportunity-container'}>
      {status === 'loading' &&
        <Grid item xs={12} container justifyContent='center' alignItems='center'
              sx={{ width: '100%', height: height }}>
          <Grid item>
            <LoaderSpinner type='Bars' color='#175da8' secondaryColor={'#6abed5'} height={70} width={70} />
          </Grid>
        </Grid>}
      {status === 'success' &&
        <Grid item xs={12} ref={ref}>
          <OpportunitiesSummary filters={filters} outcomes={outcomes} impactLevers={impactLevers}
                                onClick={handleClickBestOpportunity}
                                selected={selectedOpportunities}
                                bestOpportunities={bestOpportunities} />
        </Grid>
      }
      {status === 'success' &&
        <Grid item xs={12}>
          {segments ?
            <Grid container flexWrap='nowrap' spacing={2} columnSpacing={4} sx={{
              height: (height - summaryHeight - 8) > 350 ? height - summaryHeight - 8 : 'auto',
            }}>
              <Grid item xs={3} container alignItems={'center'} sx={{ height: '100%' }}>
                <OpportunitiesLegend data={segments}
                                     handleClick={handleLegendClick}
                                     activeLegend={activeLegend}
                />
              </Grid>
              <Grid item xs={9} sx={{ height: '100%' }}>
                <OpportunitiesChart data={segments} yLabel={'Elasticity or Impact Potential'}
                                    xLabel={xLabel}
                                    redraw={redrawChart}
                                    onClick={handleClick} />
              </Grid>
            </Grid>
            : <AlertCard severity={'info'} height={height - 24}
                         message={t('No opportunities data available!')}
                         marginRight={0} />}
        </Grid>
      }
    </Grid>
  );
};

export default OpportunitiesView;
